.thankyou {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.thankyou__inner {
	flex-grow: 1;
	padding-top: 20px;
	text-align: center;
}

.thankyou__header {
	padding-bottom: 20px;
}

.thankyou__button {
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;

	&:hover {
		border: 1px solid @red;
	}
}