.form-incut-bg {
	background-color: #404040;
	background-image: url(../img/bg/bg-form.png);
	background-repeat: no-repeat;
	background-position: top right;
	background-size: cover;
	color: #fff;
	padding-top: 27px;
	padding-bottom: 77px;

	@media (max-width: 1199px) {
		padding-top: 23px;
		padding-bottom: 64px;
	}

	@media (max-width: 991px) {
		padding-top: 18px;
		padding-bottom: 48px;
	}

	@media (max-width: 767px) {
		padding-bottom: 30px;
	}

	@media (max-width: 479px) {
		padding-bottom: 50px;
	}

}

.form-incut__title {
	font-weight: 400;
}

