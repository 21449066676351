.service-bg {
	background-color: #000;
	background-image: url(../img/bg/bg.jpg);
	background-repeat: repeat-y;
	background-position: top center;
	background-size: cover;
	color: #fff;
	padding-top: 28px;
	padding-bottom: 70px;

	@media (max-width: 1199px) {
		padding-top: 25px;
		padding-bottom: 60px;
	}

	@media (max-width: 991px) {
		padding-top: 17px;
		padding-bottom: 46px;
	}

	@media (max-width: 767px) {
		padding-bottom: 30px;
	}
}

.service {
	width: 1100px;
	margin-left: auto;
	margin-right: auto;

	@media (max-width: 1199px) {
		width: 900px;
	}

	@media (max-width: 991px) {
		width: 700px;
	}

	@media (max-width: 767px) {
		width: auto;
	}
}

.service-item {
	margin-top: 46px;
	border-radius: 10px;
  background-color: rgb(41, 41, 41);
  box-shadow: 0px 8px 13.14px 4.86px rgba(0, 0, 0, 0.05);
  padding: 22px 10px 25px 10px;

  @media (max-width: 1199px) {
  	margin-top: 39px;
  	padding: 17px 8px 20px 8px;
  }

  @media (max-width: 991px) {
  	margin-top: 30px;
  	padding: 14px 5px 15px 5px;
  }

  @media (max-width: 767px) {
  	padding: 10px 0px;
  }

  @media (max-width: 575px) {
  	width: 400px;
  	margin-left: auto;
  	margin-right: auto;
  }

  @media (max-width: 479px) {
  	width: auto;
  }
}

.service-item__title {
	padding-left: 76px;
  font-size: 26px;
  font-family: "PT Sans";
  font-weight: 600;
	padding-bottom: 13px;

	@media (max-width: 1199px) {
		padding-left: 69px;
		font-size: 22px;
		padding-bottom: 11px;
	}

	@media (max-width: 991px) {
		font-size: 16px;
		padding-left: 59px;
		letter-spacing: 0.35px;
		padding-bottom: 10px;
	}

	@media (max-width: 767px) {
		padding-left: 0;
		text-align: center;
	}

  @media (max-width: 575px) {
  	font-size: 16px;
  }
}

ul.service-item__list {
	margin: 0;
  font-size: 19px;
  line-height: 2.15;
  padding-bottom: 5px;

  @media (max-width: 1199px) {
  	font-size: 15px;
  }

  @media (max-width: 991px) {
  	font-size: 13px;
  	padding-bottom: 11px;
  	padding-left: 17px;
  }

  @media (max-width: 767px) {
  	font-size: 9px;
  }

  @media (max-width: 575px) {
  	font-size: 15px;
  	line-height: 1.6;
  	padding-left: 33px;
  }

  @media (max-width: 480px) {
  	font-size: 11px;
  	padding-left: 24px;
  }
}

.service-item__button {
	margin-left: 50px;
	margin-right: 0;
	width: 198px;
	height: 41px;

	@media (max-width: 1199px) {
		width: 165px;
		height: 34px;
		margin-left: 48px;
	}

	@media (max-width: 991px) {
		margin-left: 44px;
		width: 123px;
		height: 26px;
	}

	@media (max-width: 767px) {
		margin-left: auto;
		margin-right: auto;
	}
}