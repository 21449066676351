html, body {
	height: 100%;
	min-width: 320px;
}

body {
	font-family: 'PT Sans', sans-serif;
	font-size: 20px;
	line-height: 1.2;
}

//font-family: 'Exo 2', sans-serif;
//font-family: 'PT Sans', sans-serif;

a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: @red;
    text-decoration: none;
  }
}

.title {
  font-size: 46px;
  font-family: "Exo 2";
  font-weight: 600;
  text-align: center;

  @media (max-width: 1199px) {
    font-size: 36px;
  }

  @media (max-width: 992px) {
    font-size: 28px;
    letter-spacing: 0.3px;
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
}

.sub-title {
	padding-top: 31px;
	text-align: center;
  font-size: 36px;
  font-family: "PT Sans";
  font-weight: 600;
  letter-spacing: -0.3px;

  @media (max-width: 1199px) {
    padding-top: 26px;
    font-size: 30px;
    letter-spacing: -0.1px;
  }

  @media (max-width: 991px) {
    padding-top: 20px;
    font-size: 21px;
    letter-spacing: 0.5px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}