.promo__form {
	display: flex;
	justify-content: center;
	position: relative;
	font-family: 'Exo 2', sans-serif;

	@media (max-width: 767px) {
		flex-wrap: wrap;
		align-items: center;
	}

	@media (max-width: 479px) {
		flex-wrap: nowrap;
		flex-direction: column;
	}
	}

	.form__input {
		border: 1px solid #000;
		border-radius: 8px;
		width: 275px;
		height: 57px;
		margin-right: 33px;
		padding-left: 15px;
		display: flex;
		align-items: center;

		@media (max-width: 1199px) {
			width: 230px;
			height: 47px;
			font-size: 17px;
			margin-right: 28px;
		}

		@media (max-width: 991px) {
			width: 172px;
			height: 35px;
			font-size: 11px;
			margin-right: 20px;
		}

		@media (max-width: 767px) {
			margin-bottom: 20px;
		}

		@media (max-width: 479px) {
			margin-right: 0;
			width: 230px;
			height: 47px;
			font-size: 17px;
		}
	}

	.form__button {
		width: 272px;
		height: 57px;
		font-size: 24px;
		margin: 0;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (max-width: 1199px) {
			width: 228px;
			height: 47px;
			font-size: 20px;
		}

		@media (max-width: 991px) {
			width: 169px;
			height: 35px;
			font-size: 14px;
		}

		@media (max-width: 479px) {
			width: 228px;
			height: 47px;
			font-size: 20px;
		}

		&:hover {
			border-color: @red;
		}
	}

	.form__label  {
		position: absolute;
		padding-left: 31px;
		font-size: 10px;
		font-family: "Exo 2";
		bottom: -37px;

		@media (max-width: 1199px) {
			padding-left: 24px;
			font-size: 9px;
			bottom: -31px;
		}

		@media (max-width: 991px) {
			font-size: 7px;
			padding-left: 12px;
			bottom: -26px;
		}

		@media (max-width: 767px) {
			padding-left: 12px;
			font-size: 7px;
			bottom: -26px;
		}

		@media (max-width: 479px) {
			font-size: 9px;
			padding-left: 23px;
			bottom: -31px;
		}

		&::before {
			content: "";
			background-image: url(../img/icons/icon-check.png);
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			height: 17px;
			width: 19px;
			position: absolute;
			left: 0;

			@media (max-width: 1199px) {
				height: 15px;
				width: 17px;
			}

			@media (max-width: 991px) {
				height: 9px;
				width: 10px;
			}

			@media (max-width: 479px) {
				height: 15px;
				width: 17px;
			}
		}
	}

	.form1__label {
		right: 112px;
		width: 270px;

		@media (max-width: 1199px) {
			width: 227px;
			right: 93px;
		}

		@media (max-width: 991px) {
			right: 68px;
			width: 169px;
		}

		@media (max-width: 767px) {
			margin-left: auto;
			margin-right: auto;
			right: auto;
			width: 169px;
		}

		@media (max-width: 479px) {
			width: 228px;
		}
	}

.form-incut {
	padding-top: 34px;
	display: flex;
	justify-content: center;
	position: relative;

	@media (max-width: 1199px) {
		padding-top: 29px;
	}

	@media (max-width: 991px) {
		padding-top: 20px;
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
	}

	@media (max-width: 479px) {
		flex-wrap: nowrap;
		flex-direction: column;
		align-items: center;
	}
	}

	.form2__input {
		margin-right: 19px;
		width: 290px;
		height: 39px;
		font-size: 15px;

		@media (max-width: 1199px) {
			width: 242px;
			height: 32px;
			margin-right: 15px;
			font-size: 12px;
		}

		@media (max-width: 991px) {
			width: 180px;
			height: 24px;
			font-size: 8px;
		}

		@media (max-width: 767px) {
			width: 172px;
			height: 35px;
			font-size: 11px;
			margin-right: 20px;
		}

		@media (max-width: 479px) {
			margin-right: 0;
			width: 230px;
			height: 47px;
			font-size: 17px;
		}
	}

	#form2__submit {
		width: 198px;
		height: 37px;
		font-size: 19px;

		@media (max-width: 1199px) {
			width: 166px;
			height: 31px;
			font-size: 16px;
		}

		@media (max-width: 991px) {
			width: 122px;
			height: 23px;
			font-size: 11px;
		}

		@media (max-width: 991px) {
			width: 169px;
			height: 35px;
			font-size: 14px;
		}

		@media (max-width: 479px) {
			width: 228px;
			height: 47px;
			font-size: 20px;
		}
	}

	.form2__label {
		right: 147px;
		width: 199px;
		bottom: -46px;
		text-align: left;

		@media (max-width: 1199px) {
			width: 167px;
			right: 125px;
			bottom: -41px;
			font-size: 8px;
		}

		@media (max-width: 991px) {
			right: 65px;
			width: 169px;
			padding-left: 15px;
			bottom: -26px;
			font-size: 6px;
		}

		@media (max-width: 767px) {
			margin-left: auto;
			margin-right: auto;
			right: auto;
			width: 169px;
		}

		@media (max-width: 479px) {
			width: 228px;
			bottom: -31px;
			padding-left: 23px;
			font-size: 8px;
		}

		&::before {
			background-image: url(../img/icons/icon-check2.png);
			width: 20px;

			@media (max-width: 1199px) {
				width: 18px;
			}

			@media (max-width: 991px) {
				width: 11px;
			}

			@media (max-width: 479px) {
				width: 18px;
			}
		}
	}

.contact-form {
	font-family: 'Exo 2', sans-serif;

	@media (max-width: 767px) {
		position: absolute;
		top: 308px;
		right: 22px;
	}

	@media (max-width: 319px) {
		left: auto;
		right: auto;
		width: 90%;
		top: 440px;
	}
	}

	.form3__input {
		width: 242px;
		height: 36px;
		font-size: 16px;
		padding-left: 44px;
		margin-right: 0;
		margin-bottom: 14px;

		@media (max-width: 1199px) {
			width: 203px;
			height: 31px;
			padding-left: 37px;
			font-size: 13px;
			margin-bottom: 11px;
		}

		@media (max-width: 991px) {
			width: 150px;
			height: 23px;
			margin-bottom: 9px;
			border-radius: 4px;
			font-size: 10px;
			padding-left: 28px;
		}

		@media (max-width: 319px) {
			width: 203px;
			height: 31px;
			padding-left: 37px;
			font-size: 13px;
			margin-bottom: 11px;
			margin-left: auto;
			margin-right: auto;
		}
	}

	#form3__submit {
		margin-top: 25px;
		width: 242px;
		height: 36px;
		font-size: 20px;
		padding-right: 19px;

		@media (max-width: 1199px) {
			margin-top: 20px;
			height: 31px;
			width: 203px;
			font-size: 17px;
			padding-right: 15px;
		}

		@media (max-width: 991px) {
			margin-top: 15px;
			width: 150px;
			height: 23px;
			font-size: 12px;
			padding-right: 11px;
		}

		@media (max-width: 319px) {
			margin-top: 20px;
			height: 31px;
			width: 203px;
			font-size: 17px;
			padding-right: 15px;
			margin-left: auto;
			margin-right: auto;
		}
	}

.modal-form {
	margin-left: auto;
	margin-right: auto;
	width: 193px;
	font-family: 'Exo 2', sans-serif;
}

.form4__input {
	width: 100%;
	height: 40px;
	margin-bottom: 12px;
	font-size: 11px;
	border-color: #fff;
	background-color: #404040;
	color: #fff;

	&::-webkit-input-placeholder {color:#fff;}
	&::-moz-placeholder          {color:#fff;}/* Firefox 19+ */
	&:-moz-placeholder           {color:#fff;}/* Firefox 18- */
	&:-ms-input-placeholder      {color:#fff;}

}

#form4__submit {
	width: 100%;
	margin-top: 60px;
	height: 46px;
	font-size: 21px;
}

.form4__label {
	right: auto;
	left: auto;
	bottom: 73px;
	width: 185px;
	padding-left: 50px;
	font-size: 8px;

	&::before {
			background-image: url(../img/icons/icon-check2.png);
			left: 12px;
			width: 30px;
			height: 26px;
		}
}