.promo {
padding-top: 48px;
padding-bottom: 54px;

	@media (max-width: 1199px) {
		padding-top: 40px;
		padding-bottom: 45px;
	}

	@media (max-width: 991px) {
		padding-top: 29px;
		padding-bottom: 34px;
	}

	@media (max-width: 767px) {
		padding-top: 10px;
	}
}

.promo__title {
	margin: 0;
	padding: 0;
	text-align: center;
	font-size: 50px;
  font-family: "Exo 2";
  font-weight: 700;
  letter-spacing: -1.4px;
  line-height: 1.1;
  padding-bottom: 62px;

  @media (max-width: 1199px) {
  	font-size: 42px;
  	padding-bottom: 51px;
  }

  @media (max-width: 991px) {
  	font-size: 30px;
  	letter-spacing: -0.3px;
  	line-height: 1.2;
  	padding-bottom: 35px;
  }

  @media (max-width: 767px) {
  	font-size: 25px;
  	padding-bottom: 15px;
  }

  @media (max-width: 479px) {
  	font-size: 20px;
  }
}

.promo-items {
	display: flex;
	justify-content: space-between;
	padding-bottom: 65px;

	@media (max-width: 1199px) {
		padding-bottom: 52px;
	}

	@media (max-width: 991px) {
		flex-wrap: wrap;
		padding-bottom: 17px;
		justify-content: center;
	}

	@media (max-width: 767px) {
		flex-wrap: wrap;
		padding-bottom: 0px;
	}
}

.promo-item {
	width: 183px;

	@media (max-width: 1199px) {
		width: 157px;
	}

	@media (max-width: 991px) {
		margin-bottom: 20px;
		width: 115px;
	}

	@media (max-width: 767px) {
		margin-right: 19px;
		margin-left: 19px;
	}

	@media (max-width: 479px) {
		margin-right: 10px;
		margin-left: 10px;
	}
}

.promo-item__icon {
	display: block;
	height: 144px;
	width: 144px;
	margin-left: auto;
	margin-right: auto;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	margin-bottom: 16px;

	@media (max-width: 1199px) {
		height: 120px;
		width: 120px;
		margin-bottom: 14px;
	}

	@media (max-width: 991px) {
		height: 90px;
		width: 90px;
		margin-bottom: 10px;
	}

	// Icons 1-5
		&1 {
			background-image: url(../img/icons/promo/icon-promo1.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo1-hover.png);
			}
		}

		&2 {
			background-image: url(../img/icons/promo/icon-promo2.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo2-hover.png);
			}
		}

		&3 {
			background-image: url(../img/icons/promo/icon-promo3.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo3-hover.png);
			}
		}

		&4 {
			background-image: url(../img/icons/promo/icon-promo4.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo4-hover.png);
			}
		}

		&5 {
			background-image: url(../img/icons/promo/icon-promo5.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo5-hover.png);
			}
		}

		&6 {
			background-image: url(../img/icons/promo/icon-promo6.png);

			&:hover {
				background-image: url(../img/icons/promo/icon-promo6-hover.png);
			}
		}

}

.promo-item__desc {
  font-size: 14px;
  font-family: "Exo 2";
  line-height: 1.7;
  text-align: center;

  @media (max-width: 1199px) {
  	font-size: 12px;
  }

  @media (max-width: 991px) {
  	font-size: 9px;
  }
}
