.modal-dialog {

	@media (max-width : 479px) {
		margin: 0;
	}
}

.modal-content {
  background-color: #404040;
  color: #fff;
  width: 320px;
  height: 312px;
  margin-left: auto;
  margin-right: auto;

	@media (max-width : 479px) {
		width: 100%;
		border-radius: 0;
		margin-top: 0;
		margin-bottom: 0;
	}
}

.modal-header {
	margin-left: auto;
	margin-right: auto;
	padding-top: 32px;
	padding-bottom: 9px;
	border-bottom: none;
}

.modal-title {
  font-size: 24px;
  font-family: "Exo 2";
}
