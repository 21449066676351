.contacts-bg {
	padding-top: 21px;
	padding-bottom: 27px;

	@media (max-width: 1199px) {
		padding-top: 17px;
		padding-bottom: 22px;
	}

	@media (max-width: 991px) {
		padding-top: 13px;
		padding-bottom: 16px;
	}

	@media (max-width: 767px) {
		height: 460px;
	}

	@media (max-width: 319px) {
		height: 620px;
	}
}

.contacts {
	font-size: 13px;
	font-family: 'Exo 2', sans-serif;
	font-weight: 500;
	padding-top: 2px;
	padding-bottom: 38px;

	@media (max-width: 1199px) {
		font-size: 11px;
		padding-top: 1px;
		padding-bottom: 32px;
	}

	@media (max-width: 991px) {
		width: 235px;
		font-size: 9px;
		padding-top: 1px;
		padding-bottom: 24px;
	}

	@media (max-width: 767px) {
		position: absolute;
		top: 290px;
	}

	@media (max-width: 420px) {
		width: 45%;
	}

	@media (max-width: 319px) {
		font-size: 11px;
		width: 295px;
	}
}

.contact__item {
	padding-top: 16px;
	padding-left: 44px;
	position: relative;
	line-height: 1.1;

	@media (max-width: 1199px) {
		padding-left: 40px;
		padding-top: 13px;
	}

	@media (max-width: 991px) {
		padding-top: 9px;
		padding-left: 28px;
	}

	&::before {
		content: "";
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		position: absolute;
		left: 0;
		height: 32px;
		width: 32px;

		@media (max-width: 1199px) {
			height: 26px;
			width: 26px;
		}

		@media (max-width: 991px) {
			height: 20px;
			width: 20px;
		}
	}
	}

	.contact__item--adress {
		&::before {
			background-image: url(../img/icons/contacts/icon-contact1.png);
		}
	}

	.contact__item--phone {
		&::before {
			background-image: url(../img/icons/contacts/icon-contact2.png);
		}
	}

	.contact__item--email {
		&::before {
			background-image: url(../img/icons/contacts/icon-contact3.png);
		}
	}

	.contact__item--worktime {

		&::before {
			background-image: url(../img/icons/contacts/icon-contact4.png);
		}
	}

.contact-map {
	margin-top: 44px;
	height: 311px;

	@media (max-width: 1199px) {
		margin-top: 36px;
		height: 262px;
	}

	@media (max-width: 991px) {
		margin-top: 27px;
		height: 193px;
	}

	@media (max-width: 767px) {
		position: absolute;
		top: 0px;
		left: 0;
		right: 0;
		height: 250px;
	}

}