.button {
	display: flex;
	justify-content: center;
	align-items: center;
  border-radius: 8px;
  background-image: linear-gradient( 90deg, rgb(233,25,25) 0%, rgb(255,46,46) 100%, rgb(236,121,28) 100%);
  width: 166px;
  height: 34px;
  margin-right: 20px;
	color: #fff;
	font-size: 19px;
	text-decoration: none;
	cursor: pointer;
	border: 1px solid @red;

	@media (max-width: 1199px) {
		margin-right: 18px;
		font-size: 16px;
		width: 140px;
		height: 28px;
		margin-top: -3px;

	}

	@media (max-width : 991px) {
		margin-top: -5px;
		margin-right: 13px;
		width: 104px;
		height: 21px;
		border-radius: 5px;
		font-size: 11px;
		letter-spacing: 0.4px;
	}

	&:hover {
 		text-decoration: none;
 		background-color: #fff;
 		background-image: none;
 		border-color: #fff;
 		color: @red;
	}
}

.button--header {
	@media (max-width : 767px) {
		display: none;
	}

	&:hover {
		box-shadow: 0 0 10px 6px #fff;
	}
}