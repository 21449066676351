.footer-bg {
	padding-top: 15px;

	@media (max-width: 1199px) {
		padding-top: 11px;
	}

	@media (max-width: 991px) {
		padding-top: 7px;
	}

	@media (max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 5px;
		position: relative;
	}

	@media (max-width: 379px) {
		padding-bottom: 7px;
	}
}

.footer {
	padding-bottom: 14px;

	@media (max-width: 1199px) {
		padding-bottom: 12px;
	}

	@media (max-width: 991px) {
		padding-bottom: 9px;
	}

	@media (max-width: 767px) {
		position: relative;
	}
}

.contact--footer {
	@media (max-width: 479px) {
		margin-left: auto;
		margin-right: auto;
	}
}

.footer-phone {
	padding-top: 22px;
	padding-bottom: 10px;

	@media (max-width: 1199px) {
		padding-top: 20px;
	}

	@media (max-width: 991px) {
		padding-top: 0px;
		padding-bottom: 20px;
	}

	&::before {

		@media (max-width: 991px) {
			top: auto;
		}
	}
}

.email--footer {
	display: none;

	@media (max-width: 767px) {
		display: block;
		top: -2px;
		right: 44px;
		margin-left: 0;
		font-size: 17px;
	}

	@media (max-width: 479px) {
		right: 50%;
		transform: translateX(50%);
		top: 20px;
	}
}

.footer-links {
	padding-left: 320px;
	font-size: 15px;
	display: flex;

	@media (max-width: 1199px) {
		font-size: 12px;
		padding-left: 270px;
	}

	@media (max-width: 991px) {
		font-size: 9px;
		padding-left: 205px;
	}

	@media (max-width: 767px) {
		padding-left: 0;
		margin-left: auto;
		margin-right: auto;
	}
}

a.footer-link {
	display: block;
	margin-right: 15px;
}