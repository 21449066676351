nav.navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.nav__toggle {
	display: none;

	@media (max-width: 767px) {
		display: block;
		text-align: right;
		font-size: 38px;
		position: absolute;
		top: 9px;
		right: 19px;
		width: 100px;
		height: 100px;
		z-index: 2;
		padding-top: 7px;
		padding-right: 5px;
	}

	@media (max-width: 319px) {
		position: inherit;
		padding-right: 7px;
		padding-top: 10px;
		top: 0;
		right: 0;
		width: auto;
		height: auto;
	}

	&:hover {
		color: @red;
		text-decoration: none;
		cursor: pointer;
	}
}

ul.nav-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	text-align: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.1px;
  font-family: "Exo 2";
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-right: 65px;

  @media (max-width: 1199px) {
  	font-size: 12px;
  	margin-right: 50px;
  }

	@media (max-width : 991px) {
		font-size: 9px;
		margin-right: 30px;
	}

	@media (max-width: 767px) {
		display: none;
		margin-right: 0;
		font-size: 23px;
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url(../img/bg/bg.jpg);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		min-width: 320px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	@media (max-width: 479px) {
		font-size: 16px;
	}

	&--open {

		@media (max-width: 767px) {
			display: flex;
		}
	}

	li {
		margin: 0;
		padding: 0;
		display: inline-block;

		@media (max-width: 767px) {
			margin-bottom: 40px;

			&:last-child {
				margin-bottom: 0;
			}

			@media (max-width: 479px) {
				margin-bottom: 40px;
			}
		}
	}

	a {
		color: #fff;
		text-decoration: none;

		&:hover, &:focus, &:active {
			color: @red;
			text-decoration: none;
		}
	}
}
