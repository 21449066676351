.about-bg {
	background-color: #000;
	background-image: url(../img/bg/bg.jpg);
	background-repeat: repeat-y;
	background-position: center;
	background-size: cover;
	color: #fff;
	padding-top: 0px;
	padding-bottom: 1px;
}

p.about__text {
  font-size: 19px;
  line-height: 1.25;
  font-family: "PT Sans";

  @media (max-width: 1199px) {
  	font-size: 16px;
  	line-height: 1.2;
  }

  @media (max-width: 991px) {
  	font-size: 12px;
  }

	@media (max-width: 767px) {
		font-size: 10px;
	}

	@media (max-width: 575px) {
		font-size: 12px;
	}
}

.about__images {
	padding-top: 32px;
	display: flex;
	justify-content: space-between;
	padding-bottom: 32px;

	@media (max-width: 1199px) {
		padding-top: 27px;
		padding-bottom: 27px;
	}

	@media (max-width: 991px) {
		padding-top: 22px;
		padding-bottom: 22px;
	}

	@media (max-width: 575px) {
		flex-direction: column;
		align-items: center;
	}
}

img.about__img {
	height: 240px;

	@media (max-width: 1199px) {
		height: 200px;
	}

	@media (max-width: 991px) {
		height: 150px;
	}

	@media (max-width: 767px) {
		height: 110px;
	}

	@media (max-width: 575px) {
		height: 240px;
		margin-bottom: 30px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media (max-width: 479px) {
		height: 200px;
	}
}

