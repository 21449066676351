.pluses {
	display: flex;
	justify-content: space-around;

	@media (max-width: 575px) {
		flex-wrap: wrap;
	}
}

.plus-item {
	padding-top: 40px;
	width: 180px;

	@media (max-width: 1199px) {
		padding-top: 33px;
		width: 160px;
	}

	@media (max-width: 991px) {
		padding-top: 25px;
		width: 110px;
	}

	@media (max-width: 575px) {
		width: 240px;
		text-align: center;
		margin-left: auto;
		margin-right: auto;
	}
}

img.plus-item__icon {
	width: 98px;
	padding-bottom: 13px;

	@media (max-width: 1199px) {
		width: 81px;
		padding-bottom: 12px;
	}

	@media (max-width: 991px) {
		width: 61px;
		padding-bottom: 8px;
	}

	@media (max-width: 575px) {
		width: 98px;
		padding-bottom: 0px;
	}
}

.plus-item__title {
  font-size: 16px;
  font-family: "PT Sans";
  font-weight: 500;
  line-height: 1;
  padding-bottom: 25px;

  @media (max-width: 1199px) {
  	font-size: 14px;
  	padding-bottom: 18px;
  }

  @media (max-width: 991px) {
  	font-size: 10px;
  	padding-bottom: 14px;
  }

  @media (max-width: 575px) {
		font-size: 14px;
	}

	@media (max-width: 319px) {
		font-size: 16px;
	}
}

.plus-item__desc {
  font-size: 14px;
  font-family: "PT Sans";

  @media (max-width: 1199px) {
  	font-size: 12px;
  }

  @media (max-width: 991px) {
  	font-size: 9px;
  }

  @media (max-width: 575px) {
		font-size: 12px;
		text-align: left;
	}
}