.header-bg {
	background-color: #000;
	background-image: url(../img/bg/bg.jpg);
	background-repeat: repeat-y;
	background-position: center;
	background-size: cover;
	color: #fff;
	padding-top: 5px;
	padding-bottom: 20px;

	@media (max-width: 1199px) {
		padding-top: 2px;
		padding-bottom: 15px;
	}

	@media (max-width : 991px) {
		padding-top: 4px;
		padding-bottom: 11px;
	}

	@media (max-width : 767px) {
		padding-top: 15px;
		padding-bottom: 30px;
	}

	@media (max-width: 319px) {
		padding-bottom: 20px;
		padding-top: 5px;
	}
}

.header {
	display: flex;
	align-items: center;
	font-family: "Exo 2";
	padding-bottom: 17px;

	@media (max-width: 1199px) {
		padding-bottom: 14px;
	}

	@media (max-width : 991px) {
		padding-bottom: 13px;
	}

	@media (max-width : 767px) {
		justify-content: space-between;
		padding-bottom: 0;
	}
}

.logo {
	background-image: url(../img/logo.png);
	background-repeat: no-repeat;
	background-position: left center;
	background-size: cover;
	width: 158px;
	height: 56px;
	margin-right: 150px;
  text-align: right;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	@media (max-width: 1199px) {
		height: 47px;
		width: 132px;
		margin-right: 125px;
	}

	@media (max-width : 991px) {
		height: 35px;
		width: 98px;
		margin-right: 92px;
	}

	@media (max-width: 767px) {
		display: none;
	}
}

.office {
	padding-top: 16px;
	flex-grow: 1;

	@media (max-width: 1199px) {
		font-size: 17px;
		letter-spacing: -0.1px;
	}

	@media (max-width : 991px) {
		font-size: 12px;
		letter-spacing: 0.3px;
		padding-top: 9px;
	}

	@media (max-width : 767px) {
		display: none;
	}
}

.header-phone {
	display: block;
	padding: 18px 1px 14px 0;
	text-align: right;
	font-size: 18px;
	font-family: 'PT Sans', sans-serif;
	font-weight: 700;
	letter-spacing: 0.75px;
	position: relative;

	@media (max-width: 1199px) {
		font-size: 16px;
		padding-right: 3px;
		padding-top: 17px;
		padding-bottom: 15px;
		letter-spacing: 0.2px;
	}

	@media (max-width : 991px) {
		padding-top: 11px;
		font-size: 10px;
		padding-right: 2px;
		letter-spacing: 1px;
	}

	@media (max-width : 767px) {
		font-size: 16px;
		margin-right: 0px;
		margin-left: 20px;
		padding-top: 0;
	}

	&::before {
		content: "";
		background-image: url(../img/icons/header/icon-header-phone.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 15px;
		height: 15px;
		position: absolute;
		left: -5px;

		@media (max-width: 1199px) {
			width: 12px;
			height: 12px;
			left: -4px;
			top: 19px;
		}

		@media (max-width : 991px) {
			left: -4px;
			top: 12px;
			width: 9px;
			height: 9px;
		}

		@media (max-width : 767px) {
			width: 15px;
			height: 15px;
			left: -20px;
			top: 2px;
		}
	}
}

.email {
	display: block;
	width: 160px;
	font-size: 14px;
	position: relative;

	@media (max-width: 1199px) {
		width: 137px;
		font-size: 12px;
	}

	@media (max-width : 991px) {
		font-size: 8px;
		width: 100px;
		letter-spacing: 0.3px;
	}

	@media (max-width : 767px) {
		position: absolute;
		font-size: 16px;
		top: 40px;
		margin-left: 30px;
	}

	&::before {
		content: "";
		background-image: url(../img/icons/header/icon-header-email.png);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		width: 16px;
		height: 11px;
		position: absolute;
		left: -26px;
		top: 5px;

		@media (max-width: 1199px) {
			left: -21px;
			top: 4px;
			height: 9px;
			width: 14px;
		}

		@media (max-width : 991px) {
			height: 6px;
			width: 10px;
			top: 1px;
			left: -17px;
		}

		@media (max-width: 767px) {
			width: 16px;
			height: 11px;
			left: -30px;
			top: 5px;
		}
	}
}